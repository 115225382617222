import { useState } from "react";
import UsersList from "../../features/users/UsersList";

const Users = () => {
  return (
    <>
      <UsersList />
    </>
  );
};

export default Users;
